import React, {useEffect, useState} from 'react';
import RoundButton from 'components/buttons/round-button/round-button';
import FormInput from 'components/inputs/form-input/form-input';
import {useTranslation} from 'react-i18next';
import {vars} from 'utils/variables';

const {REG_EXP} = vars;

const RecoveryConfirmForm = (props) => {
	const {onInputChanged, passwordComparison, sendConfirmRequest, isOtpValid} = props;
	const {t} = useTranslation();

	const [localValid, setLocalValid] = useState(isOtpValid);

	useEffect(() => {
		if (localValid !== isOtpValid) {
			setLocalValid(isOtpValid);
		}
	}, [isOtpValid]);

	return (
		<div className={'password-recovery-component '}>
			<div className={'component-subtitle'}>{t('PASSWORD_RECOVERY_SUBTITLE')}</div>
      <FormInput
        type={'text'}
        label={t('PASSWORD_RECOVERY_OTP')}
        onValueChanged={onInputChanged.bind(this, 'otp')}
        validation={{
          group: 'recoveryGroup',
          rules: [
            {type: 'required', message: t('ERROR_MSG.REQUIRED')},
          ]
        }}
      />
      <FormInput
        type={'password'}
        label={t('PASSWORD_RECOVERY_NEW_PASSWORD')}
        onValueChanged={onInputChanged.bind(this, 'password')}
        validation={{
          group: 'recoveryGroup',
          rules: [
            {type: 'required', message: t('ERROR_MSG.REQUIRED')},
            {type: 'pattern', message: t('ERROR_MSG.WRONG_SYMBOLS'), pattern: REG_EXP.PASSWORD_PATTERN},
          ]
        }}
      />
      <FormInput
        type={'password'}
        label={t('PASSWORD_RECOVERY_COMPARE_PASSWORD')}
        onValueChanged={onInputChanged.bind(this, 'password')}
        validation={{
          group: 'recoveryGroup',
          rules: [
            {type: 'required', message: t('ERROR_MSG.REQUIRED')},
            {type: 'compare', message: t('ERROR_MSG.WRONG_PASSWORD_CONFIRM'), comparisonTarget: passwordComparison},
          ]
        }}
      />
			<RoundButton
				name={'recovery-change-password'}
				text={t('PASSWORD_RECOVERY_CHANGE_PASSWORD')}
				icon={null}
				onClick={sendConfirmRequest}
				customClass={'size14 new-button'}
			/>
		</div>
	);
};

export default RecoveryConfirmForm;
