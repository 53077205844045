import React from 'react';
import RoundButton from 'components/buttons/round-button/round-button';
import {useTranslation} from "react-i18next";
import FormInput from 'components/inputs/form-input/form-input';

const RecoveryInitForm = (props) => {
  const {onEmailChanged, sendRecoveryRequest} = props;
  const {t} = useTranslation();

  return (
    <div className={'password-recovery-component'}>
      <div className={'component-subtitle'}>{t('PASSWORD_RECOVERY_SUBTITLE')}</div>
      <FormInput
        type={'text'}
        label={t('PASSWORD_RECOVERY_EMAIL_PLACEHOLDER')}
        onValueChanged={onEmailChanged}
        validation={{
          group: 'recoveryGroup',
          rules: [
            {type: 'required', message: t('ERROR_MSG.REQUIRED')},
          ]
        }}
      />
      <RoundButton
        name={'recovery-recover-password'}
        text={t('PASSWORD_RECOVERY_BUTTON')}
        icon={null}
        onClick={sendRecoveryRequest}
        customClass={'size14 new-button'}
      />
    </div>
  );
};

export default RecoveryInitForm;
