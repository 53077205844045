import React from 'react';
import validationEngine from "devextreme/ui/validation_engine";
import {apiRequest} from "services/async";
import {notifyApp} from "utils/notifyWrapper";
import {vars} from "utils/variables";
import {sha512} from 'utils/functions';

import RecoveryInitForm from './recoveryInitForm';
import RecoveryConfirmForm from './recoveryConfirmForm';
import RecoverySuccessForm from './recoverySuccessForm';

const initialState = {
	login: null,
	otp: '',
	isRecoveryRequestPerform: false,
	isConfirmRequestPerform: false,
	urlid: null,
	password: null,
	isOtpValid: true,
};

class PasswordRecovery extends React.Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	static getDerivedStateFromProps(props, state) {
		if (props.clearState) {
			return initialState;
		}
		return state;
	}

	sendRecoveryRequest = async () => {
		const {login} = this.state;
		const {setSendChannelID} = this.props;
		const {NOTIFY} = vars;
		const result = validationEngine.validateGroup('recoveryGroup');

		if (result.isValid) {
			const response = await apiRequest({
				operation: 'Login/RestorePasswordInit',
				data: {
					Params: {
						Login: login
					}
				}
			});

			if (response && response.data.ResponseCode === '000') {
				setSendChannelID(response.data.Response.SendChannelID);
				this.setState({
					isRecoveryRequestPerform: true,
					urlid: response.data.Response.Urlid,
				});
			} else {
				notifyApp(response.data.ResponseText, NOTIFY.ERROR);
			}
		}
	};

	sendConfirmRequest = async () => {
		const {otp, urlid, password} = this.state;
		const {NOTIFY} = vars;
		const result = validationEngine.validateGroup('recoveryGroup');

		if (!otp) {
			this.setState({
				isOtpValid: false,
			});

			return;
		}

		if (result.isValid) {
			const hashedPassword = await sha512(password);

			if (hashedPassword) {
				const response = await apiRequest({
					operation: 'Login/RestorePasswordConfirm',
					data: {
						Params: {
							Code: otp,
							NewPassword: hashedPassword,
							Urlid: urlid,
						}
					}
				});

				if (response && response.data.ResponseCode === '000') {
					this.setState({
						isConfirmRequestPerform: true,
					});
				} else {
					notifyApp(response.data.ResponseText, NOTIFY.ERROR);
				}
			} else {
				notifyApp('Client WebAPI error', NOTIFY.ERROR);
			}
		}
	};

	onInputChanged = (name, {value}) => {
		this.setState({
			[name]: value,
		});
	};

	onEmailChanged = ({value}) => {
		this.setState({
			login: value
		});
	};

	passwordComparison = () => {
		return this.state.password;
	};

	otpFieldFocusCallback = () => {
		this.setState({
			isOtpValid: true,
		})
	}

	render() {
		const {otp, isRecoveryRequestPerform, isConfirmRequestPerform, login, isOtpValid} = this.state;

		if (isConfirmRequestPerform) {
			return (
				<RecoverySuccessForm
					handleClose={this.props.handleClose}
				/>
			);
		}

		return isRecoveryRequestPerform ? (
			<RecoveryConfirmForm
				passwordComparison={this.passwordComparison}
				onInputChanged={this.onInputChanged}
				value={otp}
				isOtpValid={isOtpValid}
				otpFieldFocusCallback={this.otpFieldFocusCallback}
				sendConfirmRequest={this.sendConfirmRequest}
			/>
		) : (
			<RecoveryInitForm
				value={login}
				onEmailChanged={this.onEmailChanged}
				sendRecoveryRequest={this.sendRecoveryRequest}
			/>
		);
	}
}

export default PasswordRecovery;