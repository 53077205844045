import React, {useEffect, useState} from 'react';
import FormInput from 'components/inputs/form-input/form-input';
import RoundButton from 'components/buttons/round-button/round-button';
import {useTranslation} from 'react-i18next';
import PasswordRecovery from 'components/popup/login/passwordRecovery';
import RecoveryPopupComponent from 'components/popup/recovery-popup';
import {languages, vars} from 'utils/variables';
import classNames from 'classnames';
import {sha512} from 'utils/functions';
import validationEngine from 'devextreme/ui/validation_engine';
import {getApplicationSettings, logInRequest} from 'services/async';
import {notifyApp} from 'utils/notifyWrapper';
import {Link} from 'react-router-dom';
import PhoneInput from '../../components/inputs/phone-input/phone-input';

const {REG_EXP, NOTIFY, SUCCESS_API_CODE} = vars;

const NewLogin = (props) => {
  const {onLoginClick} = props;
  const {i18n, t} = useTranslation();

  const [isRecoveryVisible, setIsRecoveryVisible] = useState(false);
  const [sendChannelID, setSendChannelID] = useState(-1);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [info, setInfo] = useState({
    InfoText: '',
    SendChannelID: 0,
  });
  const [username, setUsername] = useState('');
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);

  const logIn = (username, response) => {
    if (response && response.InfoText) {
      setInfo(response);
      setUsername(username);
    } else {
      onLoginClick(username);
    }
  }

  const sendLoginRequest = async () => {
    const {NOTIFY, AUTH_TYPE} = vars;
    const result = validationEngine.validateGroup('loginGroup');

    if (!result.isValid) {
      notifyApp('Please fix the validation errors', NOTIFY.ERROR);
      return;
    }

    const hashedPassword = await sha512(password);

    if (hashedPassword) {
      const request = await logInRequest(login, hashedPassword);

      if (request.isSuccess) {
        if (parseInt(request.authType) === AUTH_TYPE.SIMPLE) {
          // get settings
          const settingsResponse = await getApplicationSettings();

          if (settingsResponse.isSuccess) {
            window.localStorage.setItem('userTypeID', settingsResponse.userTypeID);
            logIn(settingsResponse.userName, request.response);
          } else {
            notifyApp(settingsResponse.error, NOTIFY.ERROR);
          }
        } else {
          /*this.setState({
            isOtp: true,
          }, request.infoText ? () => {
            setInfo(request.response);
          } : null);*/
        }
      } else {
        notifyApp(request.error, NOTIFY.ERROR);
      }
    } else {
      notifyApp('Client WebAPI error', NOTIFY.ERROR);
    }
  }

  const openRecovery = (e) => {
    e.preventDefault();
    setIsRecoveryVisible(true);
  }

  const closeRecovery = () => {
    setIsRecoveryVisible(false);
  }

  return (
    <div className={'adaptive-block-right'}>
      <div className={'login-form'}>
        <div className={'middle-block'}>
          <p className={'form-title'}>Log in to SharPay</p>
          {isPhoneLogin ? (
            <PhoneInput
              value={login}
              type={'phone'}
              label={t('COMMON.LOGIN_PHONE_ACCOUNT_PLACEHOLDER')}
              validation={{
                group: 'loginGroup',
                rules: [
                  {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                  {type: 'pattern', message: t('ERROR_MSG.WRONG_PHONE'), pattern: REG_EXP.PHONE_NUMBER},
                ],
              }}
              onInput={({event}) => {
                const value = event.target.value.replace('+', '');
                setLogin(value);

                if (!(/^\d{3}/.test(value))) {
                  setIsPhoneLogin(false);
                }
              }}
            />
          ) : (
            <FormInput
              value={login}
              label={t('COMMON.LOGIN_ACCOUNT_PLACEHOLDER')}
              type={'text'}
              validation={{
                group: 'loginGroup',
                rules: [
                  {type: 'required', message: t('ERROR_MSG.REQUIRED')},
                ],
              }}
              onValueChanged={({value}) => {
                setLogin(value);
              }}
              /*onInput={({event}) => {
                setLogin(event.target.value);

                if (/^\d{3}/.test(event.target.value)) {
                  setIsPhoneLogin(true);
                }
              }}*/
            />
          )}
          <FormInput
            label={t('COMMON.LOGIN_PASS_PLACEHOLDER')}
            type={'password'}
            validation={{
              group: 'loginGroup',
              rules: [
                {type: 'required', message: 'Password is required'},
              ]
            }}
            onValueChanged={({value}) => {
              setPassword(value);
            }}
          />
          <div className={'forgot-password'}>
            <a href='/recovery' onClick={openRecovery}>Forgot Password?</a>
          </div>
          <RoundButton
            text={t('COMMON.LOGIN')}
            icon={''}
            onClick={sendLoginRequest}
            customClass={'size14 new-button'}
          />
          <RecoveryPopupComponent
            visible={isRecoveryVisible}
            container={'#login-page'}
            title={t('PASSWORD_RECOVERY_TITLE')}
            maxWidth={650}
            handleClose={closeRecovery}
            channelId={sendChannelID}
          >
            <PasswordRecovery
              handleClose={closeRecovery}
              clearState={!isRecoveryVisible}
              setSendChannelID={setSendChannelID}
            />
          </RecoveryPopupComponent>
        </div>

        <div className={'bottom-block'}>
          <p className={'secondary-text'}>Choose language</p>
          <div className={'language-switcher'}>
            {languages.map((language) => {
              const currentLanguage = i18n.language.split('-')[0];
              const langClass = {
                'language-item': true,
                'selected': currentLanguage === language.name,
              };

              return (
                <div
                  className={classNames(langClass)}
                  key={language.name}
                  onClick={() => {
                    if (i18n.language !== language.name) {
                      i18n.changeLanguage(language.name).then(() => {
                        if (typeof HelpCrunch === 'function') {
                          // eslint-disable-next-line no-undef
                          HelpCrunch('setPhraseList', language.name);
                        }
                      }).catch((error) => {
                        console.error(error);
                      });
                    }
                  }}
                >
                  <img
                    src={language.imgSrc}
                    alt={language.name}
                  />
                  <span>{language.name.toUpperCase()}</span>
                </div>
              );
            })}
          </div>
          <p className={'secondary-text'}>
            Don’t have an account? <Link className="link" to="/registration">{t('COMMON.REGISTRATION')}</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default NewLogin;
