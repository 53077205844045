import React, {useEffect, useState} from 'react';
import {Popup} from 'devextreme-react';
import {useTranslation} from 'react-i18next';
import PopupSelectBox from './popup-selectbox';
import {notifyApp} from 'utils/notifyWrapper';
import Validator, {EmailRule, PatternRule, RequiredRule} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine'
import {getCurrencyList, merchantCreateOrder} from 'services/async';
import CopyButton from 'components/buttons/copy-button/copy-button';
import useRoundIconInput from 'utils/customHooks/useRoundIconInput';
import {Button} from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import {vars} from 'utils/variables';

const {REG_EXP: {DASHED_PHONE_NUMBER}} = vars;

const position = {
  'my': 'top',
  'at': 'top',
  'of': '#main-content',
  collision: 'fit',
  boundaryOffset: '20 40',
};

const commonInputProps = {
  isNavigationIcons: true,
  iconFill: '#007AFF',
  customClass: 'round-input',
  height: 36,
};

const CreateOrderPopup = ({order, hide, merchantId}) => {
  const {t} = useTranslation();
  const [currencies, setCurrencies] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(order.redirectUrl);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    getCurrencies().then((result) => {
      setCurrencies(result);
    }).catch((error) => {
      notifyApp(error);
    });
  }, []);

  const getCurrencies = async () => {
    const currencyRequest = await getCurrencyList();

    if (currencyRequest.isSuccess) {
      return currencyRequest.list;
    } else {
      throw new Error(currencyRequest.error);
    }
  }

  const changeCurrency = ({value}) => {
    setSelectedCurrency(value);
  }

  const [externalOrderId, externalOrderIdInput] = useRoundIconInput({
    ...commonInputProps,
    placeholder: `${t('TRANSACTIONS.ORDER_NO')}*`,
    id: 'order-num',
    validator: <Validator validationGroup={'create-order'}>
      <RequiredRule/>
    </Validator>
  });

  const [description, descriptionInput] = useRoundIconInput({
    ...commonInputProps,
    placeholder: `${t('TRANSACTIONS.ORDER_DESCRIPTION')}*`,
    id: 'order-desc',
    validator: <Validator validationGroup={'create-order'}>
      <RequiredRule/>
    </Validator>
  });

  const [amount, amountInput] = useRoundIconInput({
    ...commonInputProps,
    placeholder: `${t('TRANSACTIONS.ORDER_AMOUNT')}*`,
    inputType: 'number',
    id: 'order-amount',
    validator: <Validator validationGroup={'create-order'}>
      <RequiredRule/>
    </Validator>
  });

  const [customerFirstName, customerFirstNameCustomerFirstNameInput] = useRoundIconInput({
    ...commonInputProps,
    iconName: 'person',
    placeholder: t('TRANSACTIONS.NAME'),
    id: 'user-name',
  });

  const [customerLastName, customerLastNameInput] = useRoundIconInput({
    ...commonInputProps,
    iconName: 'person',
    placeholder: t('TRANSACTIONS.LAST_NAME'),
    id: 'user-last-name',
  });

  const [customerPhone, customerPhoneInput] = useRoundIconInput({
    ...commonInputProps,
    iconName: 'phone',
    placeholder: `${t('TRANSACTIONS.PHONE')}*`,
    id: 'user-phone',
    validator: <Validator validationGroup={'create-order'}>
      <RequiredRule/>
      <PatternRule pattern={DASHED_PHONE_NUMBER} message={'Invalid phone number'}/>
    </Validator>
  });

  const [customerEmail, customerEmailInput] = useRoundIconInput({
    ...commonInputProps,
    iconName: 'email',
    placeholder: 'Email*',
    id: 'user-email',
    validator: <Validator validationGroup={'create-order'}>
      <RequiredRule/>
      <EmailRule/>
    </Validator>
  });

  const validationList = [externalOrderId, description, amount, selectedCurrency, customerPhone, customerEmail];

  useEffect((e) => {
    const emptyFields = [];

    validationList.forEach((field, index) => {
      if (!field) {
        emptyFields.push(index);
      }
    });

    setIsValid(emptyFields.length === 0);
  }, validationList);

  return (
    <Popup
      visible={order.showOrderForm}
      container={'#root'}
      dragEnabled={false}
      closeOnOutsideClick={false}
      showTitle={false}
      maxWidth={500}
      height={'auto'}
      maxHeight={'90%'}
      position={position}
      onHiding={hide}
    >
      <div className={'app-popup-inner'}>
        <div
          className={'app-popup-close'}
          onClick={hide}
        >
          <i className={'mdi mdi-close-circle-outline'}/>
        </div>
        <div className={'app-popup-content'}>
          <div className={'app-popup-head'}>
            <div className={'app-popup-title'}>
              {t('TRANSACTIONS.PAYMENT_LINK')}
            </div>
          </div>
          {redirectUrl ? (
            <div className={'full-width'}>
              <div className={'padded-text with-copy-button-row'}>
                <CopyButton value={redirectUrl}/>
                <div className={'text-ellipsis'}>
                  {redirectUrl}
                </div>
              </div>
              <div className={'popup-buttons to-right'}>
                <Button
                  width={150}
                  height={40}
                  text={t('COMMON.CLOSE')}
                  type='default'
                  stylingMode='outlined'
                  onClick={hide}
                />
              </div>
            </div>
          ) : (
            <ScrollView className={'app-popup-fields-wrapper'}>
              <div className={'app-popup-fields-inner'}>
                {externalOrderIdInput}
                {descriptionInput}
                {amountInput}

                <div className={'app-popup-select-wrapper'}>
                  <label htmlFor='order-currency' className={'inp-label'}>
                    {`${t('TRANSACTIONS.ORDER_CURRENCY')}*`}
                  </label>
                  <PopupSelectBox
                    height={36}
                    items={currencies}
                    value={selectedCurrency}
                    changeHandler={changeCurrency}
                    displayExpr={'Code'}
                    valueExpr={'ID'}
                    id={'order-currency'}
                  >
                    <Validator validationGroup={'create-order'}>
                      <RequiredRule/>
                    </Validator>
                  </PopupSelectBox>
                </div>

                {customerFirstNameCustomerFirstNameInput}
                {customerLastNameInput}
                {customerPhoneInput}
                <div className={'last-item'}>
                  {customerEmailInput}
                </div>

                <div className={'popup-buttons'}>
                  <Button
                    width={'50%'}
                    height={40}
                    text={t('MAIN.ADD_ACCOUNT.CANCEL')}
                    type='default'
                    stylingMode='outlined'
                    onClick={hide}
                  />
                  <Button
                    width={'50%'}
                    height={40}
                    text={t('TRANSACTIONS.CREATE')}
                    type='default'
                    stylingMode='contained'
                    onClick={async () => {
                      const result = validationEngine.validateGroup('create-order');

                      if (result.isValid) {
                        const request = await merchantCreateOrder({
                            MerchantApiProfileID: merchantId,
                            ExternalOrderID: externalOrderId,
                            Description: description,
                            Amount: amount,
                            CurrencyID: selectedCurrency,
                            CustomerPhone: customerPhone,
                            CustomerEmail: customerEmail,
                            CustomerFirstName: customerFirstName,
                            CustomerLastName: customerLastName,
                          }
                        );

                        if (request.isSuccess) {
                          setRedirectUrl(request.response);
                        } else {
                          notifyApp(request.error);
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </ScrollView>
          )}
        </div>
      </div>
    </Popup>
  );
}

export default CreateOrderPopup;